@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: 0.02rem;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikLight.otf");
  font-weight: 300;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikRegular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikMedium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikSemibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikBold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "GRAPHIK";
  src: url("../public/fonts/GraphikBlack.otf");
  font-weight: 900;
}
